<template>
  <div>
    <TButton
      content="Create a new support post"
      :to="lodash.getReferenceLink('support','new')"
      icon="cil-plus"
      class="mb-3"
    />
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="helper.supports"
      resource="supports"
      @click-clear-filter="clearFilter"
      enterable
      reloadable
      @click-reload="filterChange"
      class="table-custom"
      deletable
    >
      <template #title="{ item }">
        <td>
          <div class="d-flex flex-wrap align-items-baseline">
            <CBadge color="info" class="d-flex">
              #<TMessageText :value="item.id" />
            </CBadge>
            <CBadge color="primary" class="mx-1">
              <TMessageDateTime :content="item.created_at" dateOnly />
            </CBadge>
            <CBadge color="success" v-if="checkSeen(item.logs)">
              <TMessage content="Seen" />
            </CBadge>
            <CBadge color="danger" v-else>
              <TMessage content="Not seen" />
            </CBadge>
          </div>
          <TMessageText
            :value="item.title"
            class="h5 mb-1"
            :messageOptions="{ truncate: 2 }"
          />
          <TMessage
            :content="item.description"
            noTranslate
            description
            italic
            :truncate="2"
          />
        </td>
      </template>
      <template #creator="{ item }">
        <td>
          <TMessageText v-if="item.creator" :value="item.creator.name" />
          <TMessageText v-else :value="item.creator_id" />
        </td>
      </template>
      <template #type_id="{ item }">
        <td>
          <TMessageText
            v-if="item.support_type"
            :value="item.support_type.name"
          />
        </td>
      </template>
      <template #deadline="{ item }">
        <td>
          <TMessageDateTime
            v-if="item.deadline"
            :content="item.deadline"
            dateOnly
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #closed_at="{ item }">
        <td>
          <CBadge :color="item.closed_at ? 'danger' : 'success'">
            <TMessage :content="item.closed_at ? 'Closed' : 'Active'" />
          </CBadge>
          <TMessageDateTime
            v-if="item.closed_at"
            :content="item.closed_at"
            dateOnly
          />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" />
        </td>
      </template>
      <template #type_id-filter>
        <SSelectSupportTypes
          :value.sync="filter.type_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #creator-filter>
        <SSelectCustomer
          :value.sync="filter.creator_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #deadline-filter>
        <TInputDateTimeRange
          :value.sync="deadlineRange"
          @update:value="setDeadlineFilter"
        />
      </template>
      <template #closed_at-filter>
        <SelectStatus :value.sync="statusFilter" @update:value="filterChange" />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectStatus from "./SelectStatus.vue";

export default {
  components: { SelectStatus },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px", _classes: "" },
        {
          key: "title",
          label: "Support post",
          _style: "width: 350px; min-width: 350px",
        },
        {
          key: "creator",
          label: "Creator",
          _classes: "",
          _style: "width: 250px; min-width: 250px",
        },
        {
          key: "type_id",
          label: "Support type",
          _classes: "text-nowrap",
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "deadline",
          label: "Deadline",
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "closed_at",
          label: "Status",
          _classes: "text-nowrap",
          _style: "width: 220px; min-width: 220px",
        },
      ],
      statusFilter: "",
      filter: {},
      filter_between: {},
      deadlineRange: {},
    };
  },
  created() {
    this.$store.dispatch("helper.supports.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "helper.supports.list",
      detail: "helper.supports.detail",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
        [`filter[${this.statusFilter}]`]: this.statusFilter ? 1 : 0,
      });
      this.$store.dispatch("helper.supports.apply-query", filterFields);
    },
    setDeadlineFilter({ start, end }) {
      this.filter_between["deadline"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.deadlineRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
    checkSeen(logs) {
      return logs
        .filter((x) => x.type_id == "read")
        .some((x) => x.creator_id == this.meID);
    },
  },
};
</script>

<style scoped>
.table-custom >>> .table-responsive {
  min-height: 400px;
}
</style>
